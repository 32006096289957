<template>
  <div class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>业务中台</el-breadcrumb-item>
           <el-breadcrumb-item>优惠券</el-breadcrumb-item>
           <el-breadcrumb-item>优惠券审核</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
      <!-- 表头 -->
      <el-row class="content-box">
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>名称/编号：</label>
                  <el-input class="width-220"  v-model="param.condition" placeholder="名称/业务编号" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>券类型：</label>
                  <el-select v-model="param.type" class="width-220" :placeholder="$t('msg.select')" clearable>
                     <el-option
                           v-for="(label, value) in dictData['coupon-type']"
                           :key="value"
                           :label="label"
                           :value="value">
                     </el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item">
                  <label>创建时间：</label>
                  <date-packer class="width-220"  :datePacker="dateRange" @setDatePacker="getDatePacker"></date-packer>
               </el-row>
               <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
               <el-button type="primary"  plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <div class="table-box">
            <div class="table-head-btn">
               <el-button-group class="m-right-10">
                  <el-button :class="param.status === '' ? 'bg-gradient' : ''" @click="changeStatus('')" >全部</el-button>
                  <el-button :class="param.status === 'TO_BE_REVIEWED' ? 'bg-gradient' : ''" @click="changeStatus('TO_BE_REVIEWED')" >待审核</el-button>
                  <el-button :class="param.status === 'PROCESSING' ? 'bg-gradient' : ''" @click="changeStatus('PROCESSING')" >进行中</el-button>
                  <el-button :class="param.status === 'TURN_DOWN' ? 'bg-gradient' : ''" @click="changeStatus('TURN_DOWN')" >已驳回</el-button>
               </el-button-group>
            </div>
            <el-table
                  ref="mainTable"
                  v-loading="loading"
                  :data="tableData"
                  :stripe="true">
               <el-table-column label="序号" width="80">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="id" label="业务编号" width="120"></el-table-column>
               <el-table-column prop="mainTitle" label="优惠券名称" width="150"></el-table-column>
               <el-table-column label="类型" width="100">
                  <template slot-scope="scope">
                     {{ scope.row.type | filterType(that) }}
                  </template>
               </el-table-column>
               <el-table-column label="审核状态" width="100">
                  <template slot-scope="scope">
                     {{ scope.row.status | filterStatus(that) }}
                  </template>
               </el-table-column>
               <el-table-column prop="remark" show-overflow-tooltip label="优惠券内容"></el-table-column>
               <el-table-column prop="issueCount" label="发行量" width="100"></el-table-column>
               <el-table-column label="有效期" width="180">
                  <template slot-scope="scope">
                     <el-row v-if="scope.row.validType === 'APPOINT_DATE'">
                        <p>{{ scope.row.validDate }}</p>
                        <p>{{ scope.row.expiraDate }}</p>
                     </el-row>
                     <el-row v-else>
                        {{ scope.row.validDays }}天
                     </el-row>
                  </template>
               </el-table-column>
               <el-table-column prop="createTime" label="创建时间" width="120"></el-table-column>
               <el-table-column prop="xiangou" label="创建人" width="120"></el-table-column>
               <el-table-column :label="$t('msg.operating')" fixed="right">
                  <template slot-scope="scope">
                  <el-button  type="text" @click="detail = scope.row;visible = true; ">
                     <el-link type="primary">详情</el-link>
                  </el-button>
                  <el-button type="text" v-show="scope.row.status === 'TO_BE_REVIEWED'"  @click="updateStatus(scope.row.id, 'PROCESSING')">
                     <el-link type="primary">通过</el-link>
                  </el-button>
                  <el-button  type="text" v-show="scope.row.status === 'TO_BE_REVIEWED'" @click="updateStatus(scope.row.id, 'TURN_DOWN')" >
                     <el-link type="primary">驳回</el-link>
                  </el-button>
                  </template>
               </el-table-column>
            </el-table>
         </div>
         <!-- 活动详情 -->
         <el-dialog class="detail-dialog-box" title="优惠券详情" :visible.sync="visible" width="660px">
            <el-row class="title">
               <h6>{{ detail.mainTitle }}</h6>
            </el-row>
            <el-row class="text-list">
               <el-row>
                  <el-col :span="12">
                     <p><span class="width-100">优惠券副标题</span>{{ detail.subTitle }}</p>
                  </el-col>
                  <el-col :span="12">
                     <p><span class="width-100">优惠券类型</span>{{ detail.type | filterType(that) }}</p>
                  </el-col>
               </el-row>
               <el-row>
                  <el-col :span="12">
                     <p><span class="width-100">参与商品数</span></p>
                  </el-col>
                  <el-col :span="12">
                     <p><span class="width-100">优惠内容</span></p>
                  </el-col>
               </el-row>
               <el-row>
                  <el-col :span="12">
                     <p><span class="width-100">单用户限领数</span>{{ detail.limitQuantity }}</p>
                  </el-col>
                  <el-col :span="12">
                     <p><span class="width-100">发行量</span>{{ detail.issueCount }}</p>
                  </el-col>
               </el-row>
               <el-row>
                  <el-col :span="12">
                     <p><span class="width-100">活动开始时间</span>{{ detail.validDate }}</p>
                  </el-col>
                  <el-col :span="12">
                     <p><span class="width-100">活动结束时间</span>{{ detail.expiraDate }}</p>
                  </el-col>
               </el-row>
               <el-row>
                  <p><span class="width-100">优惠券简介</span>{{ detail.remark }}</p>
               </el-row>
            </el-row>
            <el-row class="btm-btn">
               <el-button class="bg-gradient" @click="visible = false">关闭</el-button>
            </el-row>
         </el-dialog>
         <!-- 分页 -->
         <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>
   </div>
</template>

<script>
import { urlObj , business } from "@/api/interface"; // ,coupon
import { coupon } from  '@/api/interface/business'
import { getDict } from "@/common/js/common";
import { mapState } from 'vuex'
export default {
   data(){
      return{
         tableData: [],
         loading:true,
         limit: 1,
         total: 1,
         page: 1,
         visible: false,
         that: this,
         dateRange: [],
         param: {
            type: '',
            status: '',
            condition: '',
            beginTime: '',
            endTime: ''
         },
         detail: {},
         del_success: "删除成功！",
         add_success: "添加成功！",
         update_success: '修改成功！',
         userName_no_null: '用户名不能为空！',
         mobile_no_null: '手机号不能为空！',
         nickName_no_null: '昵称不能为空！',
         confirm: '确定',
         cancel: '取消',
         confirm_remove: '确定移除？',
         prompt: '提示！',
      }
   },
   computed: {
      ...mapState(['hotelInfo', 'dictData'])
   },
   mounted() {
      this.limit = sessionStorage.getItem('pageSize') * 1
      getDict(['coupon-type', 'coupon-state'])
      this.getCoupons()
   },
   methods: {
      // 获取优惠券列表
      getCoupons(){
         const url = coupon.couponPage + `?limit=${this.limit}&page=${this.page}`
         const param = { hotelId: this.hotelInfo.id, ...this.param }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.total = res.total
               this.tableData = res.records
               this.loading = false
            }
         })
      },
      // 改变优惠券状态
      changeStatus(val){
         this.page = 1
         this.param.status = val
         this.getCoupons()
      },
      // 获取登录时间段
      getDatePacker(val){
         this.dateRange = val
         let [ beginTime = null, endTime = null ] = val
         this.param.beginTime = beginTime
         this.param.endTime = endTime
      },
      // 查询优惠券
      handleQuery(bool){
         this.page = 1
         if (bool) return this.getCoupons()
         for (let k in this.param) this.param[k] = ''
         this.dateRange = []
      },
      // 改变每页数
      pageChange(num){
         this.limit = num
         this.getCoupons()
      },
      // 改变当前页
      handlePaging(num){
         this.page = num
         this.getCoupons()
      },
      // 通过/驳回
      updateStatus(id, status){
         this.$confirm( `您确定要${ status === 'PROCESSING' ? '通过' : '驳回' }当前优惠券方案吗？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            const url = coupon.couponStatus
            const param = { id, status }
            this.$axios.post(url, param).then(() => {
               this.$message({
                  showClose: true,
                  message: `审核${ status === 'PROCESSING' ? '通过！' : '驳回！'}`,
                  type: 'success'
               })
               this.getCoupons()
            })
         })
      },
   },
   filters: {
      filterStatus(val, that){
         if (val && that.dictData['coupon-state']) return that.dictData['coupon-state'][val] || val
      },
      filterType(val, that){
         if (val && that.dictData['coupon-type']) return that.dictData['coupon-type'][val] || val
      },
   },
   watch: {
      hotelInfo(newVal, oldVal) {
         oldVal && newVal.id !== oldVal.id && this.getCoupons()
      }
   }
}
</script>

<style lang="scss" scoped></style>
